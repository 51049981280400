import React from 'react'
import PropTypes from 'prop-types'
import {colors, Layout} from '@lookout/ui'

const AppIdentifierRow = ({name, id, className, rightMargin}) => (
  <Layout className={`${className}-row`} horizontal>
    <div
      className={`${className}-title`}
      css={{
        color: colors.darkGray200,
        fontSize: 13,
        marginRight: rightMargin,
        marginBottom: 10,
      }}
    >
      {name}
    </div>
    <div className={className} css={{fontSize: 14}}>
      {id}
    </div>
  </Layout>
)

AppIdentifierRow.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  rightMargin: PropTypes.number,
}

const AppIdentifiers = ({app}) => {
  const {enterpriseGuid, applicationGuid} = app.value()

  return (
    <>
      <h2 className="app-identifiers-title">
        {I18n.t('app_details.app_identifiers')}
      </h2>
      <p>{I18n.t('app_details.app_identifiers')}</p>
      <Layout className="app-identifiers-info" css={{maxWidth: 420}} vertical>
        <AppIdentifierRow
          name={I18n.t('app_details.app_id')}
          id={applicationGuid}
          className="application-id"
          rightMargin={40}
        />
        <AppIdentifierRow
          name={I18n.t('app_details.ent_id')}
          id={enterpriseGuid}
          className="enterprise-id"
          // This is to adjust the margin for ent-id column display
          // because of the text length of the left column is small
          // which makes to look bit off with the first row,
          // TODO: Can be updated to use the table and make it look better
          rightMargin={47}
        />
      </Layout>
    </>
  )
}

AppIdentifiers.propTypes = {
  app: PropTypes.shape({
    value: PropTypes.func,
  }),
}

export default AppIdentifiers
