import React, {useCallback} from 'react'
import _ from 'lodash'
import {useNavigate, useLocation} from 'react-router-dom'
import {Layout, VerticalMenuItem, SideNavPanel} from '@lookout/ui'
import {colors} from '@lookout/design'
import {MiscHome, MesApps, MesSupport} from '@lookout/dingbats'
import logoHorizontalInverted from '../../assets/images/logo-horizontal-inverted.svg'
import FooterTrigger from './footer-trigger'

const menuIconStyle = {
  width: 24,
  height: 24,
  marginRight: 16,
  verticalAlign: 'text-bottom',
  fill: 'currentColor',
}

const menuTextStyle = {
  lineHeight: '24px',
}

const SideNavHeader = () => {
  const navigate = useNavigate()

  return (
    <Layout
      className="nav-header"
      vertical
      onClick={() => navigate('/')}
      css={{
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 130,
      }}
    >
      <div css={{width: '70%'}}>
        <img
          className="logo"
          alt={I18n.t('brand.name')}
          src={logoHorizontalInverted}
          css={{display: 'block', width: '100%', height: '70%'}}
        />
        <span
          css={{
            fontSize: '9.5px',
            textTransform: 'uppercase',
            color: colors.white,
            paddingLeft: 2,
          }}
        >
          {I18n.t('main_menu.app_name')}
        </span>
      </div>
    </Layout>
  )
}

const SideNav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const routeTo = useCallback(
    route => {
      if (route !== location.pathname) navigate(route)
    },
    [location.pathname, navigate]
  )
  return (
    <SideNavPanel
      header={<SideNavHeader />}
      selected={_.thru(location.pathname, path =>
        /\/apps\/[\w-]{6}/.test(path) ? '/apps' : path
      )}
      onClick={value => {
        if (value === 'logout') navigate('/logout')
      }}
      footerTrigger={<FooterTrigger userName={global.cache.admin.name} />}
      footerMenu={[{label: I18n.t('user_menu.logout'), value: 'logout'}]}
    >
      <VerticalMenuItem
        className="home-link"
        menuKey="/"
        css={{padding: '15px 22px'}}
        onClick={routeTo}
      >
        <MiscHome css={menuIconStyle} />
        <span css={menuTextStyle}>{I18n.t('main_menu.home')}</span>
      </VerticalMenuItem>
      <VerticalMenuItem
        className="apps-link"
        menuKey="/apps"
        css={{padding: '15px 22px'}}
        onClick={routeTo}
      >
        <MesApps css={menuIconStyle} />
        <span css={menuTextStyle}>{I18n.t('main_menu.apps')}</span>
      </VerticalMenuItem>
      <VerticalMenuItem
        as="a"
        href={I18n.t('main_menu.support_link')}
        target="LookoutSupportPage"
        className="support-link"
        menuKey="support_link"
        css={{padding: '15px 22px'}}
      >
        <MesSupport css={menuIconStyle} />
        <span css={menuTextStyle}>{I18n.t('main_menu.support')}</span>
      </VerticalMenuItem>
    </SideNavPanel>
  )
}

export default SideNav
