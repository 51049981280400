import React from 'react'
import {Container} from '../micro/container'
import Markdownify from '../micro/markdownify'
import AndroidMarkdown from './android.md'

const AndroidDocs = () => (
  <Container className="android-docs-page">
    <Markdownify md={AndroidMarkdown} />
  </Container>
)

export default AndroidDocs
