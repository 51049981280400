import React, {useMemo, useCallback} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import _ from 'lodash'
import {FilterSelect} from '@lookout/ui'
import {stringifyQuery, toPlainObject} from '../../lib/utils/query-params'
import '../../../config/locales/console/en'
import {defaultQuery, isForbiddenDateRange} from './dashboard-utils'

const dashboardFilterOptions = ({apps}) => [
  {
    id: 'date_range',
    name: I18n.t('dashboard.filters.time'),
    singular: true,
    required: true,
    options: [
      {
        id: 'last_7_days',
        name: I18n.t('dashboard.filters.last_7_days'),
      },
      {
        id: 'last_30_days',
        name: I18n.t('dashboard.filters.last_30_days'),
      },
    ],
  },
  {
    id: 'app_guid',
    name: I18n.t('dashboard.filters.app'),
    singular: true,
    options: _.map(apps, ({applicationGuid: id, appName: name}) => ({
      id,
      name,
    })),
  },
]

const DashboardFilter = ({apps}) => {
  const navigate = useNavigate()
  const options = useMemo(
    () => dashboardFilterOptions({apps: apps.value()}),
    [apps]
  )
  const [searchParams] = useSearchParams()
  const query = useMemo(
    () => _.defaults({}, toPlainObject(searchParams), defaultQuery),
    [searchParams]
  )
  const value = useMemo(
    () => FilterSelect.deserialize(query, {options}),
    [options, query]
  )

  const onFilterChange = useCallback(
    val => {
      // remove values that match default query to avoid their exposure into browser URL
      const obj = _.mapValues(
        FilterSelect.serialize(val, {options}),
        (v, key) => (defaultQuery[key] === v ? undefined : v)
      )
      if (isForbiddenDateRange(obj)) {
        delete obj.date_range
      }
      const str = stringifyQuery(obj)
      navigate(str ? `?${str}` : '')
    },
    [navigate, options]
  )

  return (
    <FilterSelect
      className="dashboard-filter"
      value={value}
      options={options}
      onChange={onFilterChange}
      isOptionDisabled={({id}, selected) =>
        id === 'last_30_days' &&
        !selected.find(({groupId}) => groupId === 'app_guid')
      }
    />
  )
}

export default DashboardFilter
