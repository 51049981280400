import {clearAll, TokenCallback, TokenChecker} from '@gnosis/oauth'
import React, {Suspense} from 'react'
import {Helmet} from 'react-helmet'
import {Navigate, useRoutes} from 'react-router-dom'
// eslint-disable-next-line import/no-cycle
import App from './app'
import AppDetailsPage from './components/app-details/app-details-page'
import AppsPage from './components/apps/apps-page'
import AndroidDocs from './components/docs/android'
import IosDocs from './components/docs/ios'
import FullScreenErrorMessage from './components/errors/fullscreen-error-message'
import Fallback from './components/micro/fallback'
import OffsiteRedirect from './components/micro/offsite-redirect'
import Dashboard from './components/dashboard/dashboard'
import {setToken} from './lib/api/token-cache'
import getPageTitle from './lib/utils/page-title'

const Logout = () => {
  clearAll()
  setToken(null)
  return <OffsiteRedirect to={global.config.logout_url} />
}

const getAuthRoutes = () => [
  {path: '/login', element: <OffsiteRedirect to={global.config.login_url} />},
  {path: '/logout', element: <Logout />},
  {
    path: '/token',
    element: (
      <TokenCallback
        onDecode={setToken}
        requiredAuthorizations={['sdk']}
        render={(success, lastKnownRoute, authorized) => {
          if (success) {
            return authorized ? (
              <Navigate to={lastKnownRoute} />
            ) : (
              <Navigate to="/unauthorized" />
            )
          }
          return <Navigate to="/login" />
        }}
      />
    ),
  },
  {
    path: '/refresh-token',
    element: (
      <TokenChecker
        tokenVendorUrl={global.config.token_vendor_url}
        tokenRequest={{
          callbackUrl: global.config.token_redirect_url,
          clientId: global.config.token_vendor_client_id,
        }}
        requiredAuthorizations={['sdk']}
        onValid={setToken}
        forceRefresh
      />
    ),
  },
  {
    path: '/unauthorized',
    element: (
      <>
        <Helmet>
          <title>{getPageTitle('unauth')}</title>
        </Helmet>
        <FullScreenErrorMessage
          className="unauthorized-page"
          title={I18n.t('unauth.title')}
          description={I18n.t('unauth.details')}
        />
      </>
    ),
  },
]

const AppRoutes = () => {
  const element = useRoutes([
    ...getAuthRoutes(),
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: '/',
          element: (
            <>
              <Helmet>
                <title>{getPageTitle('home')}</title>
              </Helmet>
              <Dashboard />
            </>
          ),
        },
        {
          path: 'apps',
          element: (
            <>
              <Helmet>
                <title>{getPageTitle('apps')}</title>
              </Helmet>
              <AppsPage />
            </>
          ),
        },
        {
          path: 'apps/:applicationGuid',
          element: (
            <>
              <Helmet>
                <title>{getPageTitle('appDetails')}</title>
              </Helmet>
              <Suspense
                fallback={<Fallback className="app-details-pending" spinner />}
              >
                <AppDetailsPage />
              </Suspense>
            </>
          ),
        },
        {
          path: 'docs/android',
          element: (
            <>
              <Helmet>
                <title>{getPageTitle('androidDocs')}</title>
              </Helmet>
              <AndroidDocs />
            </>
          ),
        },
        {
          path: 'docs/ios',
          element: (
            <>
              <Helmet>
                <title>{getPageTitle('iosDocs')}</title>
              </Helmet>
              <IosDocs />
            </>
          ),
        },
        {
          path: '*',
          element: (
            <FullScreenErrorMessage
              className="not-found-page"
              title={I18n.t('error.not_found.title')}
            />
          ),
        },
      ],
    },
  ])
  return element
}

export default AppRoutes
