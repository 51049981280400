import _ from 'lodash'
import requestWithToken from '../../lib/api/request-with-token'
import {stringifyQuery} from '../../lib/utils/query-params'
import {appmgmtApiUrlFor, eventsApiUrlFor} from '../../lib/api/urls'

export const readApps = opts =>
  requestWithToken(appmgmtApiUrlFor(`/${global.cache.ent.guid}/apps`), opts)

export const readApp = ({applicationGuid, opts}) =>
  requestWithToken(
    appmgmtApiUrlFor(`/${global.cache.ent.guid}/apps/${applicationGuid}`),
    opts
  )

export const readAppVersions = ({applicationGuid, opts}) =>
  requestWithToken(
    appmgmtApiUrlFor(
      `/${global.cache.ent.guid}/apps/${applicationGuid}/versions`
    ),
    opts
  )

export const readAppToken = async ({applicationGuid, opts}) => {
  const {key} = await requestWithToken(
    appmgmtApiUrlFor(`/${global.cache.ent.guid}/apps/${applicationGuid}/token`),
    {
      method: 'POST',
      body: JSON.stringify({}),
      opts,
    }
  )
  return key
}

export const readAppEvents = ({query, ...opts}) =>
  requestWithToken(
    eventsApiUrlFor(`/events?${stringifyQuery(query, null, _.identity)}`),
    opts
  )

export const readDevice = ({deviceGuid, opts}) =>
  requestWithToken(appmgmtApiUrlFor(`/devices/${deviceGuid}`), opts)

export const createApp = ({data, ...opts}) =>
  requestWithToken(appmgmtApiUrlFor(`/${global.cache.ent.guid}/apps`), {
    method: 'POST',
    body: JSON.stringify(data),
    ...opts,
  })

export const updateApp = ({applicationGuid, data, ...opts}) =>
  requestWithToken(
    appmgmtApiUrlFor(`/${global.cache.ent.guid}/apps/${applicationGuid}`),
    {method: 'PUT', body: JSON.stringify(data), ...opts}
  )

export const deleteApp = ({applicationGuid, opts}) =>
  requestWithToken(
    appmgmtApiUrlFor(`/${global.cache.ent.guid}/apps/${applicationGuid}`),
    {
      method: 'DELETE',
      opts,
    }
  )
