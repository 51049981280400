import _ from 'lodash'
import requestWithToken from './lib/api/request-with-token'
import {appmgmtApiUrlFor} from './lib/api/urls'

export const readCache = () =>
  requestWithToken(appmgmtApiUrlFor('/console/cache'))

export const readEnt = async () => {
  const result = await requestWithToken(appmgmtApiUrlFor('/enterprises'))
  // Until the UI supports switching between multiple tenancies, we select the
  // first tenancy by default
  return _.head(result)
}
