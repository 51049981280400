import {useEffect} from 'react'

export const ResourceSuccess = ({resource, onSuccess}) => {
  const response = resource.value()
  useEffect(() => {
    onSuccess(response)
    resource.dispose()
  }, [onSuccess, resource, response])
  return null
}
