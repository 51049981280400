import {ServerError} from '@lookout/request'
import requestWithToken from '../../lib/api/request-with-token'
import {appdefApiUrlFor} from '../../lib/api/urls'
import {stringifyQuery} from '../../lib/utils/query-params'
import {isForbiddenDateRange} from './dashboard-utils'

const assertQuery = query => {
  if (isForbiddenDateRange(query)) {
    throw new ServerError('date range forbidden')
  }
}

export const readDeviceDetections = async ({query, ...opts}) => {
  assertQuery(query)
  return requestWithToken(
    appdefApiUrlFor(
      `/${global.cache.ent.guid}/device-detections/timeseries?${stringifyQuery(
        query
      )}`
    ),
    opts
  )
}

export const readDeviceRegistrations = async ({query, ...opts}) => {
  assertQuery(query)
  return requestWithToken(
    appdefApiUrlFor(
      `/${global.cache.ent.guid}/device-registrations/total?${stringifyQuery(
        query
      )}`
    ),
    opts
  )
}

export const readDetections = ({category = 'ALL', query, ...opts}) =>
  requestWithToken(
    appdefApiUrlFor(
      `/${global.cache.ent.guid}/detections/total?${stringifyQuery({
        category,
        ...query,
      })}`
    ),
    opts
  )

export const readAllDetections = async ({query, ...opts}) => {
  assertQuery(query)
  return Promise.all(
    ['APP_THREATS', 'DEVICE', 'NETWORK', 'ALL'].map(category =>
      readDetections({category, query, ...opts})
    )
  )
}
