import {Global, css} from '@emotion/react'
import {globalStyles} from '@lookout/ui'

const Style = () => (
  <>
    <Global styles={globalStyles} />
    <Global
      styles={css(import('modern-normalize/modern-normalize.css').toString())}
    />
    <Global
      styles={css(
        import('@lookout/fonts/noto-sans/noto-sans-400.css').toString()
      )}
    />
    <Global
      styles={css(
        import('@lookout/fonts/noto-sans/noto-sans-700.css').toString()
      )}
    />
  </>
)

export default Style
