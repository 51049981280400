import * as SentryBrowser from '@sentry/browser'
import {BrowserTracing} from '@sentry/tracing'
import _ from 'lodash'
import {mapValuesDeep, scrubSensitiveData} from './lib/utils/utils'

const Sentry = SentryBrowser

const sentryDsn = () => global.config.sentry_dsn

const sentryEnabled = () => !!sentryDsn()

const initSentry = () => {
  if (sentryEnabled()) {
    try {
      Sentry.init({
        dsn: sentryDsn(),
        integrations: [new BrowserTracing()],
        // TODO: Uncomment this block if we consider using mixpanel for OIC
        // beforeBreadcrumb: breadcrumb =>
        // skipping calls to Mixpanel from Sentry recording, as this is not our user activity (but a foreign APIs one)
        // _.includes(breadcrumb.data?.url, 'mixpanel.com') ? null : breadcrumb,
        beforeSend: event =>
          sentryEnabled()
            ? mapValuesDeep(event, (value, key) =>
                _.some(['url', 'from', 'to'], target => _.includes(key, target))
                  ? scrubSensitiveData(value)
                  : value
              )
            : null,
      })
      const info = {
        release: global.config.release,
        version: global.config.version,
        environment: global.config.env,
        current_locale: I18n.locale,
        release_date: global.config.release_date,
      }
      Sentry.setTags(info)
      // duplicating some tags as extras for better Sentry UX (to see this data in the page Additional Data section)
      Sentry.setExtras(info)
    } catch (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to initialize Sentry:', e)
    }
  } else {
    try {
      Sentry.close(0)
    } catch (e) {
      // eslint-disable-next-line no-console
      if (e) console?.error('Failed to close Sentry:', e)
    }
  }
}

export const captureException = (...args) =>
  sentryEnabled() && Sentry.captureException(...args)

export default initSentry
