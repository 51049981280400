import {
  colors,
  Tabs,
  Tab,
  Table,
  tableStyles,
  RiskMarker,
  typography,
  TableSortingHeader,
  sortQuery,
  sortOrder,
  useLoading,
  BarStackHorizontal,
} from '@lookout/ui'
import React, {useState, useMemo} from 'react'
import {ThemeProvider} from '@emotion/react'
import _ from 'lodash'
import {threatsSampleData} from '../charts-test-data'
import {tableLayoutStyles} from '../../../lib/styles/layout-styles'
import EmptyTableRow from '../../micro/empty-table-row'

const SEVERITIES = ['LOW', 'MEDIUM', 'HIGH']

const reduceThreatCounts = ({threatsCategoryMap, category}) => ({
  category: I18n.t(`dashboard.charts.threats.chart.${category.toLowerCase()}`),
  ..._.reduce(
    SEVERITIES,
    (result, severity) => ({...result, [severity]: 0}),
    {}
  ),
  ..._.mapValues(
    _.groupBy(threatsCategoryMap[category].values, 'severity'),
    threats => _.reduce(threats, (total, {count}) => total + count, 0)
  ),
})

const countsCellStyle = [typography.monospace, {textAlign: 'right'}]
const threatsTableStyle = {
  'thead tr th': {
    fontWeight: 700,
    color: colors.blueGray200,
  },
  tbody: {
    fontSize: 13,
    color: colors.black300,
  },
}

const NoDataTooltip = () => (
  <div className="no-chart-data-tooltip">
    {I18n.t('dashboard.charts.registrations.no_data')}
  </div>
)

const threatOppositeCategoryClassMap = {
  NETWORK: '.data-device rect, .data-app rect',
  DEVICE: '.data-network rect, .data-app rect',
  APP_THREATS: '.data-device rect, .data-network rect',
}

const DashboardThreats = ({
  detections,
  isPending,
  dateRange,
  titleStyle,
  tabsTheme = {},
}) => {
  const [threatCategory, setThreatCategory] = useState('ALL')
  const [threatSort, setThreatSort] = useState({
    sort_by: 'count',
    order: 'desc',
  })
  const {elementRef, loadingVisible} = useLoading(isPending)

  const threatsCategoryMap = useMemo(
    () => _.keyBy(detections.value(), 'category'),
    [detections]
  )
  const threats = useMemo(
    () =>
      _.orderBy(
        threatsCategoryMap[threatCategory].values,
        threatSort.sort_by,
        threatSort.order
      ),
    [threatsCategoryMap, threatCategory, threatSort]
  )

  const threatsChartData = useMemo(
    () => [
      reduceThreatCounts({threatsCategoryMap, category: 'NETWORK'}),
      reduceThreatCounts({threatsCategoryMap, category: 'DEVICE'}),
      reduceThreatCounts({threatsCategoryMap, category: 'APP_THREATS'}),
    ],
    [threatsCategoryMap]
  )

  const customStyle = useMemo(
    () =>
      threatCategory !== 'ALL'
        ? {
            [threatOppositeCategoryClassMap[threatCategory]]: {
              fillOpacity: '25%',
            },
          }
        : null,
    [threatCategory]
  )

  const isEmptyData = useMemo(
    () =>
      _.every(
        threatsChartData,
        item => !item.MEDIUM && !item.LOW && !item.HIGH
      ),
    [threatsChartData]
  )

  return (
    <figure
      ref={elementRef}
      css={{
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        '&.loading.in > :not(figcaption)': {opacity: 0.5},
      }}
    >
      <figcaption>
        <h3 className="dashboard-threats-title" css={titleStyle}>
          {loadingVisible
            ? I18n.t('doc_title.loading')
            : I18n.t('dashboard.charts.threats.title', {
                dateRange: I18n.t(`dashboard.filters.${dateRange}`),
              })}
        </h3>
      </figcaption>
      <div className="threats-chart-container" css={{paddingTop: 20}}>
        <BarStackHorizontal
          className="threats-chart"
          keys={SEVERITIES}
          y={({category}) => category}
          axisBottom={{
            tickFormat: val => I18n.toNumber(val, {precision: 0}),
          }}
          showLegend={false}
          height={140}
          margins={{top: 0, left: 80, bottom: 50}}
          yScaleConfig={{padding: 0.5}}
          barStackText={{css: {display: 'none'}}}
          css={customStyle}
          {...(isEmptyData
            ? {
                data: threatsSampleData,
                colorsRange: [colors.transparent],
                Tooltip: NoDataTooltip,
              }
            : {
                data: threatsChartData,
                colorsRange: [colors.lowRisk, colors.medRisk, colors.highRisk],
              })}
        />
      </div>
      <ThemeProvider theme={tabsTheme}>
        <Tabs>
          <Tab
            className="total-threats-tab"
            selected={threatCategory === 'ALL'}
            onClick={() => setThreatCategory('ALL')}
          >
            <div css={{color: colors.black300}}>
              {I18n.toNumber(threatsCategoryMap.ALL.sum, {precision: 0})}
            </div>
            <span>{I18n.t('dashboard.charts.threats.category.all')}</span>
          </Tab>
          <Tab
            className="app-threat-tab"
            selected={threatCategory === 'APP_THREATS'}
            onClick={() => setThreatCategory('APP_THREATS')}
          >
            <div css={{color: colors.black300}}>
              {I18n.toNumber(threatsCategoryMap.APP_THREATS.sum, {
                precision: 0,
              })}
            </div>
            <span>
              {I18n.t('dashboard.charts.threats.category.app_threats')}
            </span>
          </Tab>
          <Tab
            className="device-threat-tab"
            selected={threatCategory === 'DEVICE'}
            onClick={() => setThreatCategory('DEVICE')}
          >
            <div css={{color: colors.black300}}>
              {I18n.toNumber(threatsCategoryMap.DEVICE.sum, {
                precision: 0,
              })}
            </div>
            <span>{I18n.t('dashboard.charts.threats.category.device')}</span>
          </Tab>
          <Tab
            className="network-threat-tab"
            selected={threatCategory === 'NETWORK'}
            onClick={() => setThreatCategory('NETWORK')}
          >
            <div css={{color: colors.black300}}>
              {I18n.toNumber(threatsCategoryMap.NETWORK.sum, {
                precision: 0,
              })}
            </div>
            <span>{I18n.t('dashboard.charts.threats.category.network')}</span>
          </Tab>
        </Tabs>
      </ThemeProvider>

      <Table
        className="threats-table"
        css={[
          tableStyles.striped,
          tableLayoutStyles.fullWidth,
          threatsTableStyle,
        ]}
      >
        <thead>
          <tr>
            <TableSortingHeader
              className="threat-breakdown-header"
              title={I18n.t('dashboard.charts.threats.threat_breakdown_header')}
              sortOrder={sortOrder({
                sort_by: 'classification',
                query: threatSort,
              })}
              onClick={() =>
                setThreatSort(
                  sortQuery({sort_by: 'classification', query: threatSort})
                )
              }
            />
            <TableSortingHeader
              className="classification-header"
              title={I18n.t('dashboard.charts.threats.classification_header')}
              sortOrder={sortOrder({
                sort_by: 'category',
                query: threatSort,
              })}
              onClick={() =>
                setThreatSort(
                  sortQuery({sort_by: 'category', query: threatSort})
                )
              }
            />
            <TableSortingHeader
              className="ios-detected-header"
              title={I18n.t('dashboard.charts.threats.ios_detected_header')}
              sortOrder={sortOrder({
                sort_by: 'ios',
                query: threatSort,
              })}
              css={{textAlign: 'right !important'}}
              onClick={() =>
                setThreatSort(sortQuery({sort_by: 'ios', query: threatSort}))
              }
            />
            <TableSortingHeader
              className="android-detected-header"
              title={I18n.t('dashboard.charts.threats.android_detected_header')}
              sortOrder={sortOrder({
                sort_by: 'android',
                query: threatSort,
              })}
              css={{textAlign: 'right !important'}}
              onClick={() =>
                setThreatSort(
                  sortQuery({
                    sort_by: 'android',
                    query: threatSort,
                  })
                )
              }
            />
            <TableSortingHeader
              className="all-os-header"
              title={I18n.t('dashboard.charts.threats.all_os_header')}
              sortOrder={sortOrder({
                sort_by: 'count',
                query: threatSort,
              })}
              css={{textAlign: 'right !important'}}
              onClick={() =>
                setThreatSort(
                  sortQuery({
                    sort_by: 'count',
                    query: threatSort,
                  })
                )
              }
            />
          </tr>
        </thead>
        <tbody>
          {_.size(threats) > 0 ? (
            threats.map(threat => (
              <tr key={JSON.stringify(threat)}>
                <td className="threat-breakdown-cell" css={typography.noWrap}>
                  <RiskMarker riskLevel={threat.severity.toLowerCase()} />
                  {I18n.t(
                    `dashboard.charts.threats.threat_breakdown.${threat.classification}`
                  )}
                </td>
                <td className="threat-classification-cell">
                  {I18n.t(
                    `dashboard.charts.threats.classification.${threat.category}`
                  )}
                </td>
                <td className="threat-ios-count" css={countsCellStyle}>
                  {I18n.toNumber(threat.ios, {precision: 0})}
                </td>
                <td className="threat-android-count" css={countsCellStyle}>
                  {I18n.toNumber(threat.android, {precision: 0})}
                </td>
                <td className="threat-total-count" css={countsCellStyle}>
                  {I18n.toNumber(threat.count, {precision: 0})}
                </td>
              </tr>
            ))
          ) : (
            <EmptyTableRow>
              {I18n.t('dashboard.charts.threats.empty')}
            </EmptyTableRow>
          )}
        </tbody>
      </Table>
    </figure>
  )
}

export default DashboardThreats
