import _ from 'lodash'
import {FilterSelect, Layout, useNotifications} from '@lookout/ui'
import React, {Suspense, useCallback, useEffect, useState} from 'react'
import {useResource} from '@lookout/suspense'
import useTrackView from '../../lib/analytics/use-track-view'
import {mobileResponsivePadding} from '../../lib/styles/layout-styles'
import {belowQuery, screenXsMax} from '../../lib/styles/breakpoints'
import Fallback from '../micro/fallback'
import ActionButton from '../micro/action-button'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import {readApps} from './apps-service'
import AppsList from './apps-list'
import DeleteAppModal from './delete-app-modal'
import GetKeyModal from './get-key-modal'
import RegisterAppModal from './register-app-modal'

const REGISTER_APP_MODAL = 'REGISTER_APP_MODAL'
const GET_KEY_MODAL = 'GET_KEY_MODAL'
const EDIT_APP_MODAL = 'EDIT_APP_MODAL'
const DELETE_APP_MODAL = 'DELETE_APP_MODAL'

const tableRowActionModals = {GET_KEY_MODAL, EDIT_APP_MODAL, DELETE_APP_MODAL}

const initialModalState = {
  type: null,
  options: null,
}

const AppsPage = () => {
  useTrackView('apps')
  const [apps, isPending, readAppsTransition] = useResource(readApps)
  const [filter, setFilter] = useState(null)
  const [modal, setModal] = useState(initialModalState)
  const setModalCallback = useCallback(payload => setModal(payload), [])
  const {notifySuccess} = useNotifications()

  useEffect(() => {
    readAppsTransition()
  }, [readAppsTransition])

  return (
    <div className="apps-page">
      <Layout
        css={{
          padding: 40,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...mobileResponsivePadding,
        }}
        horizontal
      >
        <h1 css={{margin: 0}}>{I18n.t('apps_page.page_header')}</h1>
        <ActionButton
          className="register-an-app"
          onClick={() => setModal({type: REGISTER_APP_MODAL})}
        >
          {I18n.t('apps_page.register_button')}
        </ActionButton>
      </Layout>
      <div
        css={{
          marginBottom: 20,
          padding: '0 40px',
          [belowQuery({max: screenXsMax})]: {
            padding: '0px 20px',
          },
        }}
      >
        <FilterSelect
          className="apps-filter"
          placeholder={I18n.t('apps_page.filter_placeholder')}
          getNewOptionData={() => ({
            // TODO: Add query param support
            groupId: 'q',
            groupName: I18n.t('apps_page.filter_name'),
            description: I18n.t('apps_page.filter_description'),
          })}
          onChange={options => setFilter(_.head(options)?.name)}
        />
      </div>
      <NetworkErrorBoundary
        fallback={
          <Fallback
            className="apps-list-error"
            message={I18n.t('error.service.apps')}
          />
        }
      >
        <Suspense fallback={<Fallback className="apps-list-pending" spinner />}>
          {apps ? (
            <AppsList
              filter={filter}
              modals={tableRowActionModals}
              setModal={setModalCallback}
              apps={apps}
              isPending={isPending}
            />
          ) : (
            <Fallback className="apps-list-pending" spinner />
          )}
        </Suspense>
      </NetworkErrorBoundary>
      {(modal.type === REGISTER_APP_MODAL || modal.type === EDIT_APP_MODAL) && (
        <RegisterAppModal
          options={modal.options}
          onClosed={result => {
            setModal(initialModalState)
            if (result) {
              notifySuccess(
                modal.options?.applicationGuid
                  ? I18n.t('apps_page.alert.edit_app_success', result)
                  : I18n.t('apps_page.alert.new_app_success', result)
              )
              readAppsTransition()
            }
          }}
        />
      )}
      {modal.type === GET_KEY_MODAL && (
        <GetKeyModal
          options={modal.options}
          onClosed={() => setModal(initialModalState)}
        />
      )}
      {modal.type === DELETE_APP_MODAL && (
        <DeleteAppModal
          options={modal.options}
          onClosed={result => {
            setModal(initialModalState)
            if (result) {
              notifySuccess(
                I18n.t('apps_page.alert.delete_success', {
                  appName: modal.options.appName,
                })
              )
              readAppsTransition()
            }
          }}
        />
      )}
    </div>
  )
}

export default AppsPage
