import {colors, tableStyles, Layout, Table, useLoading} from '@lookout/ui'
import _ from 'lodash'
import {parseISO} from 'date-fns'
import React, {useMemo, useState} from 'react'
import {format} from '../../lib/utils/date-fns-localized'
import {mobileResponsiveMargin} from '../../lib/styles/layout-styles'
import Fallback from '../micro/fallback'
import {DoubleHeader} from '../micro/double-header'
import ActionButton from '../micro/action-button'
import {
  getAssessmentName,
  getRawAssessmentCategory,
  getAssessmentCategory,
  getIconFromCategory,
} from './assessment-strings'
import AppEventModal from './app-event-modal'

const EventDetailsCell = ({event}) => {
  if (event.assessments?.length === 0) return null
  const rawCategory = getRawAssessmentCategory(event.type)
  const Icon = getIconFromCategory(rawCategory)
  return (
    <Layout
      className="event-details-cell"
      css={{alignItems: 'center', display: 'inline-flex'}}
      horizontal
    >
      {Icon && (
        <Icon
          css={{
            height: 32,
            fill: colors.blueGray100,
            marginLeft: 8,
            width: 32,
          }}
        />
      )}
      <DoubleHeader
        top={getAssessmentCategory(rawCategory)}
        bottom={_.uniq(
          event.assessments?.map(assessment =>
            getAssessmentName(assessment.classification)
          )
        ).join(', ')}
      />
    </Layout>
  )
}

const AppEventsRow = ({event, setSelectedAppEvent}) =>
  useMemo(
    () => (
      <tr
        className="app-events-row"
        onClick={() => {
          setSelectedAppEvent(event)
        }}
      >
        <td className="app-event-type" css={{whiteSpace: 'nowrap'}}>
          {_.startCase(_.lowerCase(event.type))}
        </td>
        <td className="app-event-device-id" css={{whiteSpace: 'nowrap'}}>
          {event.target.deviceId}
        </td>
        <td className="app-event-details">
          <EventDetailsCell event={event} />
        </td>
        <td className="app-event-time">
          <DoubleHeader
            top={format(parseISO(event.serverTime), 'M/d/yyyy')}
            bottom={format(parseISO(event.serverTime), 'p')}
          />
        </td>
      </tr>
    ),
    [event, setSelectedAppEvent]
  )

const AppEvents = ({appEvents, setStreamPosition, isAppEventsPending}) => {
  const [selectedAppEvent, setSelectedAppEvent] = useState(null)
  const {events, moreEvents, streamPosition} = appEvents.value()
  const {elementRef, loadingVisible} = useLoading(isAppEventsPending)
  return (
    <>
      {!_.isEmpty(events) ? (
        <>
          <h2
            css={{
              margin: '40px 40px 20px',
              ...mobileResponsiveMargin,
            }}
          >
            {I18n.t('eventlist.heading')}
          </h2>
          <div css={{overflow: 'auto'}}>
            <Table
              className="app-events-table"
              css={[
                tableStyles.default,
                tableStyles.striped,
                tableStyles.hover,
                tableStyles.cursorPointer,
                {
                  fontSize: 14,
                  marginBottom: 40,
                  width: '100%',
                  'thead tr th': {whiteSpace: 'nowrap'},
                  'thead tr th, tbody tr td': {
                    minWidth: 200,
                    paddingLeft: 40,
                    paddingRight: 40,
                  },
                },
              ]}
            >
              <thead>
                <tr>
                  <th>{I18n.t('eventlist.column.event')}</th>
                  <th>{I18n.t('eventlist.column.device')}</th>
                  <th>{I18n.t('eventlist.column.event_detail')}</th>
                  <th>{I18n.t('eventlist.column.time')}</th>
                </tr>
              </thead>
              <tbody ref={elementRef}>
                {events &&
                  events.map(event => (
                    <AppEventsRow
                      event={event}
                      key={event.eventId}
                      setSelectedAppEvent={setSelectedAppEvent}
                    />
                  ))}
              </tbody>
            </Table>
            <Layout css={{padding: 40, justifyContent: 'center'}} horizontal>
              {moreEvents ? (
                <ActionButton
                  className="load-more-events"
                  disabled={loadingVisible}
                  onClick={() => setStreamPosition(streamPosition)}
                >
                  {loadingVisible
                    ? I18n.t('eventlist.loading_events')
                    : I18n.t('eventlist.load_more_events')}
                </ActionButton>
              ) : (
                <span className="no-more-events">
                  {I18n.t('eventlist.no_more_events')}
                </span>
              )}
            </Layout>
          </div>
        </>
      ) : (
        <Fallback
          className="app-events-filtered-empty"
          message={I18n.t('eventlist.no_events')}
        />
      )}
      {selectedAppEvent && (
        <AppEventModal
          event={selectedAppEvent}
          onClosed={() => setSelectedAppEvent(null)}
        />
      )}
    </>
  )
}

export default AppEvents
