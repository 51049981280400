import {
  Button,
  Modal,
  Panel,
  PanelFooter,
  PanelHeader,
  Layout,
  Spinner,
  Textarea,
} from '@lookout/ui'
import _ from 'lodash'
import React, {useState, Suspense} from 'react'
import {useResourceOnce} from '@lookout/suspense'
import Fallback from '../micro/fallback'
import SelectToCopy from '../micro/select-to-copy'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import {readAppToken} from './apps-service'

const CopyKey = ({appKey}) => {
  const [textRef, setTextRef] = useState(null)

  return (
    <>
      {appKey && (
        <>
          <Textarea
            className="app-key"
            cols="80"
            css={{width: '100%'}}
            onChange={_.noop}
            ref={setTextRef}
            rows="8"
            value={appKey.value()}
          />
          <SelectToCopy textElement={textRef} />
        </>
      )}
    </>
  )
}

const GetKeyModal = ({options = {}, onClosed}) => {
  const {applicationGuid} = options
  const readApp = useResourceOnce(args =>
    readAppToken({applicationGuid, ...args})
  )
  return (
    <Modal className="get-key-modal" onClosed={onClosed}>
      {({closeModal}) => (
        <Panel onClose={() => closeModal()}>
          <PanelHeader>{I18n.t('key_modal.header')}</PanelHeader>
          <NetworkErrorBoundary
            fallback={
              <Fallback
                className="get-key-error"
                message={I18n.t('error.service.apps')}
              />
            }
          >
            <Suspense
              fallback={
                <Layout
                  className="app-key-pending"
                  css={{
                    minHeight: '33vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  horizontal
                >
                  <Spinner size={10} />
                </Layout>
              }
            >
              {readApp ? (
                <CopyKey appKey={readApp} />
              ) : (
                <Fallback className="token-loading" spinner />
              )}
            </Suspense>
          </NetworkErrorBoundary>
          <PanelFooter>
            <Button onClick={() => closeModal()}>
              {I18n.t('key_modal.ok_button')}
            </Button>
          </PanelFooter>
        </Panel>
      )}
    </Modal>
  )
}

export default GetKeyModal
