export const screenXsMin = 480
export const screenSmMin = 768
export const screenMdMin = 992
export const screenLgMin = 1200
export const screenXlMin = 1440

export const screenXxsMax = screenXsMin - 1
export const screenXsMax = screenSmMin - 1
export const screenSmMax = screenMdMin - 1
export const screenMdMax = screenLgMin - 1
export const screenLgMax = screenXlMin - 1

export const belowQuery = ({max}) => `@media (max-width: ${max}px)`

export const withinQuery = ({min, max}) =>
  `@media (min-width: ${min}px) and (max-width: ${max}px)`

export const aboveQuery = ({min}) => `@media (min-width: ${min}px)`

export const maxima = {
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenLgMax,
}

export const getViewportWidth = () =>
  Math.max(
    global.document.documentElement.clientWidth || 0,
    global.window.innerWidth || 0
  )
