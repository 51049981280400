import {colors, Layout, useNotifications} from '@lookout/ui'
import React, {useState, useEffect, Suspense, useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'
import {defaults} from 'lodash'
import {useResource, useResourceOnce} from '@lookout/suspense'
import {ErrorBoundary} from 'react-error-boundary'
import ActionButton from '../micro/action-button'
import useTrackView from '../../lib/analytics/use-track-view'
import {
  mobileResponsivePadding,
  mobileResponsiveMargin,
} from '../../lib/styles/layout-styles'
import {
  belowQuery,
  screenSmMax,
  screenXsMax,
} from '../../lib/styles/breakpoints'
import {toPlainObject} from '../../lib/utils/query-params'
import RegisterAppModal from '../apps/register-app-modal'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import Fallback from '../micro/fallback'
import {readApps} from '../apps/apps-service'
import {
  readDeviceDetections,
  readDeviceRegistrations,
  readAllDetections,
} from './dashboard-service'
import DocsCollapsible from './docs-collapsible'
import DetectionsChart from './charts/detections-chart'
import RegistrationsChart from './charts/registrations-chart'
import DashboardThreats from './charts/dashboard-threats'
import DashboardFilter from './dashboard-filter'
import {defaultQuery} from './dashboard-utils'

const gridContainerStyle = {
  margin: 30,
  ...mobileResponsiveMargin,
  backgroundColor: colors.gray300,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}

const gridItemStyle = {
  margin: 10,
  minWidth: 350,
  justifyContent: 'center',
  [belowQuery({max: screenSmMax})]: {
    gridColumnEnd: 'span 2',
  },
  [belowQuery({max: screenXsMax})]: {
    marginRight: 0,
    marginLeft: 0,
    ':last-child': {
      marginBottom: 0,
    },
  },
}

const chartTitleStyle = {
  margin: '15px 20px',
  color: colors.black200,
  letterSpacing: '0.4px',
}

const tabsTheme = {
  Tabs: {
    marginBottom: 20,
    height: 80,
  },

  Tab: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    border: `1px solid ${colors.gray300}`,
    borderRight: 0,
    padding: '2px 0',
    backgroundColor: colors.white,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 1,
    '&[aria-selected=true]': {
      borderBottom: `3px solid ${colors.darkGray200}`,
      paddingBottom: 0,
    },
    ':first-of-type': {
      borderLeft: 0,
    },
    span: {
      marginTop: 10,
      fontSize: 11,
    },
  },
}

const buttonStyle = {
  marginLeft: 20,
  [belowQuery({max: screenSmMax})]: {marginLeft: 10},
}

const Dashboard = () => {
  const [searchParams] = useSearchParams()
  const query = useMemo(
    () => defaults({}, toPlainObject(searchParams), defaultQuery),
    [searchParams]
  )

  const apps = useResourceOnce(readApps)

  const [
    deviceDetections,
    isDeviceDetectionsPending,
    startDeviceDetectionsTransition,
  ] = useResource(readDeviceDetections)
  const [
    deviceRegistrations,
    isDeviceRegistrationsPending,
    startDeviceRegistrationsTransition,
  ] = useResource(readDeviceRegistrations)
  const [detections, isDetectionsPending, startDetectionsTransition] =
    useResource(readAllDetections)

  useEffect(() => {
    startDeviceRegistrationsTransition({query})
    startDeviceDetectionsTransition({query})
    startDetectionsTransition({query})
  }, [
    startDeviceRegistrationsTransition,
    startDeviceDetectionsTransition,
    startDetectionsTransition,
    query,
  ])

  const [isRegisterAppModalOpen, setRegisterAppModalOpen] = useState()
  const {notifySuccess} = useNotifications()

  useTrackView('dashboard')

  return (
    <div
      className="dashboard"
      css={{
        display: 'flex', // TODO charts: without flex 30px (20 on mobile) dashboard margin at bottom left uncovered
        flexDirection: 'column',
        minHeight: '100%',
        backgroundColor: colors.gray300,
      }}
    >
      <ErrorBoundary
        fallback={
          <Fallback
            className="dashboard-unhandled-error"
            message={I18n.t('error.generic.title')}
          />
        }
      >
        {deviceDetections && deviceRegistrations && detections ? (
          <Suspense
            fallback={<Fallback className="dashboard-charts-pending" spinner />}
          >
            <div
              css={{
                padding: '40px 40px 20px 40px',
                ...mobileResponsivePadding,
                backgroundColor: colors.white,
              }}
            >
              <Layout
                horizontal
                css={{
                  marginBottom: 20,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h1 css={{margin: 0, flex: 2, whiteSpace: 'nowrap'}}>
                  {I18n.t('dashboard.header.title')}
                </h1>
                <ActionButton
                  css={{
                    ...buttonStyle,
                    backgroundColor: colors.primary,
                    color: colors.white,
                  }}
                  onClick={() => setRegisterAppModalOpen(true)}
                  className="register-app-button"
                >
                  {I18n.t('apps_page.register_button')}
                </ActionButton>
              </Layout>
              <NetworkErrorBoundary
                fallback={
                  <div className="dashboard-filter-error">
                    {I18n.t('error.service.apps')}
                  </div>
                }
              >
                <DashboardFilter apps={apps} />
              </NetworkErrorBoundary>
              <DocsCollapsible />
            </div>

            {isRegisterAppModalOpen && (
              <RegisterAppModal
                onClosed={result => {
                  setRegisterAppModalOpen(false)
                  if (result)
                    notifySuccess(
                      I18n.t('apps_page.alert.new_app_success', result)
                    )
                }}
              />
            )}

            <div css={gridContainerStyle}>
              <div css={gridItemStyle}>
                <NetworkErrorBoundary
                  resetKeys={[deviceRegistrations]}
                  fallback={
                    <Fallback
                      className="dashboard-registrations-error"
                      message={I18n.t('error.service.dashboard')}
                    />
                  }
                >
                  <RegistrationsChart
                    titleStyle={chartTitleStyle}
                    height={380}
                    tabsTheme={tabsTheme}
                    deviceRegistrations={deviceRegistrations}
                    isPending={isDeviceRegistrationsPending}
                    dateRange={query.date_range}
                  />
                </NetworkErrorBoundary>
              </div>

              <div css={{...gridItemStyle, display: 'flex'}}>
                <NetworkErrorBoundary
                  resetKeys={[deviceDetections]}
                  fallback={
                    <Fallback
                      className="dashboard-detections-error"
                      message={I18n.t('error.service.dashboard')}
                    />
                  }
                >
                  <DetectionsChart
                    titleStyle={chartTitleStyle}
                    height={400}
                    deviceDetections={deviceDetections}
                    isPending={isDeviceDetectionsPending}
                    dateRange={query.date_range}
                    apps={apps}
                    onRegisterAppModalClick={() =>
                      setRegisterAppModalOpen(true)
                    }
                  />
                </NetworkErrorBoundary>
              </div>

              <div css={{...gridItemStyle, gridColumnEnd: 'span 2'}}>
                <NetworkErrorBoundary
                  resetKeys={[detections]}
                  fallback={
                    <Fallback
                      className="dashboard-threats-error"
                      message={I18n.t('error.service.dashboard')}
                    />
                  }
                >
                  <DashboardThreats
                    titleStyle={chartTitleStyle}
                    tabsTheme={tabsTheme}
                    detections={detections}
                    isPending={isDetectionsPending}
                    dateRange={query.date_range}
                  />
                </NetworkErrorBoundary>
              </div>
            </div>
          </Suspense>
        ) : (
          <Fallback className="dashboard-charts-pending" spinner />
        )}
      </ErrorBoundary>
    </div>
  )
}

export default Dashboard
