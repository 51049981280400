import React from 'react'
import PropTypes from 'prop-types'
import {Layout} from '@lookout/ui'

const containerStyle = {
  padding: '40px',
  position: 'relative',
}

export function Container(props) {
  return (
    <Layout css={containerStyle} {...props}>
      {props.children}
    </Layout>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
}
