/* eslint-disable no-unused-expressions */
import mixpanel from 'mixpanel-browser'
import _ from 'lodash'
import {getEntGuid} from '../utils/tenancy-helper'

// This code was copy-pasted from https://source.corp.lookout.com/frontend/mtp-console/blob/master/app/services/mixpanel-helper.js
// TODO to reuse iot somehow (maybe to extract into @lookout/trackers npm lib)

const mixpanelEnabled = () => !!global.config.mixpanel_token

const instanceName = entGuid => entGuid && `ent-${entGuid}`

const mixpanelInitialized = () => !!mixpanel[instanceName(getEntGuid())]

const trackEvent = (eventName, opts) =>
  mixpanel[instanceName(getEntGuid())].track(eventName, opts)

const eventString = name => _.map(name.split('-'), _.upperFirst).join(' ')

/*
 * Tracks a view to show in Mixpanel.
 */
export const trackView = (viewName, {tabName, ...opts} = {}) => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  try {
    trackEvent(`View - ${eventString(viewName)}`, {
      ...opts,
      ...(tabName && {'Tab Name': eventString(tabName)}),
    })
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to track view with Mixpanel:', e)
    }
  }
}

/*
 * Tracks the click to show in Mixpanel.
 */
export const trackClick = (scope, desc) => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  if (!scope) return
  try {
    trackEvent(`Click - ${eventString(scope)}`, desc && {desc})
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to track click with Mixpanel:', e)
    }
  }
}

export const trackDomClick = e => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  const el = e.target.classList.contains('mixpanel-link')
    ? e.target
    : e.target.closest('.mixpanel-link')
  if (!el) return
  const scope = el.getAttribute('data-mixpanel-link-scope')
  const desc = el.getAttribute('data-mixpanel-link-desc')
  trackClick(scope, desc)
}

export const initMixpanel = () => {
  if (!mixpanelEnabled()) return
  try {
    mixpanel.init(
      global.config.mixpanel_token,
      {
        ip: false,
        property_blacklist: ['$initial_referrer', '$referrer', '$current_url'],
      },
      instanceName(getEntGuid())
    )
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to initialize Mixpanel:', e)
    }
  }
  try {
    mixpanel[instanceName(getEntGuid())].register({
      Env: global.location.host,
      Locale: global.config.current_locale || 'en',
    })
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to register Mixpanel context:', e)
    }
  }
}
