import React from 'react'
import classes from '../../lib/utils/classes'

const EmptyTableRow = ({
  className,
  cellStyle,
  children = I18n.t('empty.text'),
}) => (
  <tr className={classes(className, 'empty')}>
    <td colSpan={999} css={cellStyle}>
      {children}
    </td>
  </tr>
)

export default EmptyTableRow
