import globalProxy from './global-proxy'

export const getLocation = () => globalProxy().location

export const assignLocation = url => globalProxy().location.assign(url)

export const reloadLocation = forcedReload =>
  globalProxy().location.reload(forcedReload)

export const replaceLocation = url => globalProxy().location.replace(url)
