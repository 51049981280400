import React, {Suspense, useEffect, useState} from 'react'
import {useResource, useResourceOnce} from '@lookout/suspense'
import {useParams} from 'react-router-dom'
import useTrackView from '../../lib/analytics/use-track-view'
import {mobileResponsiveMargin} from '../../lib/styles/layout-styles'
import Fallback from '../micro/fallback'
import {readApp, readAppEvents, readAppVersions} from '../apps/apps-service'
import NetworkErrorBoundary from '../../lib/network-error-boundary'
import AppSummary from './app-summary'
import AppIdentifiers from './app-identifiers'
import AppVersions from './app-versions'
import AppEvents from './app-events'

const AppDetailsPage = () => {
  useTrackView('app-details')
  const {applicationGuid} = useParams()
  const app = useResourceOnce(args => readApp({applicationGuid, ...args}))
  const appVersions = useResourceOnce(args =>
    readAppVersions({applicationGuid, ...args})
  )
  const [appEvents, isAppEventsPending, appEventsTransition] =
    useResource(readAppEvents)
  const [streamPosition, setStreamPosition] = useState()
  useEffect(() => {
    appEventsTransition({
      query: {
        entId: global.cache.ent.guid,
        applicationId: applicationGuid,
        limit: 10,
        streamPosition,
        order: 'desc',
      },
    })
  }, [appEventsTransition, applicationGuid, streamPosition])
  return (
    <div className="app-details-page">
      <NetworkErrorBoundary
        fallbackRender={() => (
          <Fallback
            className="app-error"
            message={I18n.t('error.service.apps')}
          />
        )}
      >
        <Suspense fallback={<Fallback className="app-pending" spinner />}>
          <div css={{margin: 30, ...mobileResponsiveMargin}}>
            <AppSummary app={app} />
          </div>
          <div css={{margin: 40, ...mobileResponsiveMargin}}>
            <AppIdentifiers app={app} />
          </div>
        </Suspense>
      </NetworkErrorBoundary>
      <NetworkErrorBoundary
        fallbackRender={() => (
          <Fallback
            className="app-error"
            message={I18n.t('error.service.apps')}
          />
        )}
      >
        <Suspense>
          <AppVersions appVersions={appVersions} />
        </Suspense>
      </NetworkErrorBoundary>
      <NetworkErrorBoundary
        fallbackRender={() => (
          <Fallback
            className="app-events-error"
            message={I18n.t('error.service.app_events')}
          />
        )}
      >
        <Suspense
          fallback={<Fallback className="app-events-pending" spinner />}
        >
          {appEvents ? (
            <AppEvents
              {...{appEvents, setStreamPosition, isAppEventsPending}}
            />
          ) : (
            <Fallback className="app-events-pending" spinner />
          )}
        </Suspense>
      </NetworkErrorBoundary>
    </div>
  )
}

export default AppDetailsPage
