import {
  colors,
  useLoading,
  Layout,
  Button,
  HyperText,
  BarStack,
} from '@lookout/ui'
import React, {useMemo} from 'react'
import {format} from 'd3-format'
import {parse} from 'date-fns'
import _ from 'lodash'
import {format as dateFormat} from '../../../lib/utils/date-fns-localized'
import protectedDeviceIcon from '../../../assets/images/protected-device.svg'

const DetectionsChartTooltip = ({date, count, total}) => (
  <div>
    <div className="detections-date">
      {dateFormat(parse(date, 'MM-dd-yyyy', new Date()), 'P')}
    </div>
    <HyperText
      className="detections-count"
      content={I18n.t('dashboard.charts.detections.count_of_devices', {
        count,
        formattedCount: '{formattedCount}',
      })}
    >
      <HyperText
        key="formattedCount"
        className="formatted-count"
        css={{fontWeight: 'bolder'}}
        content={I18n.toNumber(count, {precision: 0})}
      />
    </HyperText>
    <HyperText
      className="detections-share"
      content={I18n.t('dashboard.charts.detections.of_total', {
        count: '{detectionsShareCount}',
      })}
    >
      <HyperText
        key="detectionsShareCount"
        className="detections-share-count"
        css={{fontWeight: 'bolder'}}
        content={I18n.toPercentage(Math.round((count / total) * 100), {
          precision: 0,
        })}
      />
    </HyperText>
  </div>
)

const NoDataTooltip = () => (
  <div className="no-chart-data-tooltip">
    {I18n.t('dashboard.charts.registrations.no_data')}
  </div>
)

const chartSubtitleStyle = {
  color: colors.black300,
  fontSize: 20,
  fontWeight: 'bolder',
  // HACK a special fix fo Safari to keep bottom panel stretched
  '& + div': {
    flex: 2,
  },
}

const DetectionsChart = ({
  deviceDetections,
  isPending,
  dateRange,
  titleStyle,
  height,
  onRegisterAppModalClick,
  apps,
}) => {
  const {elementRef, loadingVisible} = useLoading(isPending)
  const isNoAppRegistered = useMemo(
    () => apps && _.isEmpty(apps.value()),
    [apps]
  )
  const deviceDetectionResult = deviceDetections.value().values
  const deviceDetectionsMaxCount = useMemo(
    () => Math.max(..._.map(deviceDetectionResult, 'count')),
    [deviceDetectionResult]
  )

  const isEmptyDeviceDetectionData = useMemo(
    () => _.every(deviceDetectionResult, item => !item.count),
    [deviceDetectionResult]
  )

  return (
    <figure
      ref={elementRef}
      css={{
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        '&.loading.in > :not(figcaption)': {opacity: 0.5},
        position: 'relative',
      }}
    >
      <figcaption>
        <h3 css={titleStyle} className="detections-chart-title">
          {loadingVisible
            ? I18n.t('doc_title.loading')
            : I18n.t('dashboard.charts.detections.title', {
                dateRange: I18n.t(`dashboard.filters.${dateRange}`),
              })}
        </h3>
      </figcaption>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          borderTop: `1px solid ${colors.gray300}`,
          padding: '20px 20px 0 20px',
          flex: 2,
        }}
      >
        <HyperText
          className="detections-chart-subtitle"
          content={I18n.t('dashboard.charts.detections.subtitle', {
            count: '{detectionsCount}',
          })}
        >
          <HyperText
            key="detectionsCount"
            className="detections-count"
            css={chartSubtitleStyle}
            content={I18n.toNumber(deviceDetections.value().sum, {
              precision: 0,
            })}
          />
        </HyperText>
        <BarStack
          className="detections-chart"
          height={height}
          margins={{top: 20, right: 20, bottom: 40, left: 45}}
          axisBottom={{
            tickFormat: val =>
              dateFormat(parse(val, 'MM-dd-yyyy', new Date()), 'P'),
          }}
          x={({date}) => date}
          y={({count}) => count}
          {...(isEmptyDeviceDetectionData
            ? {
                data: deviceDetectionResult.map(item => ({...item, count: 10})),
                color: colors.transparent,
                yNumTicks: 4,
                axisLeft: {
                  label: I18n.t('dashboard.charts.detections.num_of_devices'),
                  tickFormat: format('d'),
                },
                tooltip: NoDataTooltip,
              }
            : {
                data: deviceDetectionResult,
                yNumTicks:
                  deviceDetectionsMaxCount > 4 ? 4 : deviceDetectionsMaxCount,
                axisLeft: {
                  label: I18n.t('dashboard.charts.detections.num_of_devices'),
                  tickFormat: format(
                    deviceDetectionsMaxCount > 100 ? '.2~s' : 'd'
                  ),
                },
                tooltip: ({data: {date, count}}) => (
                  <DetectionsChartTooltip
                    date={date}
                    count={count}
                    total={deviceDetections.value().sum}
                  />
                ),
              })}
        />
      </div>
      {isEmptyDeviceDetectionData && isNoAppRegistered && (
        <Layout
          css={{
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          horizontal
        >
          <img
            src={protectedDeviceIcon}
            css={{width: 100}}
            alt={I18n.t('dashboard.docs.icon')}
          />
          <Layout
            css={{
              margin: 20,
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
            vertical
          >
            <span css={{marginBottom: 20, fontSize: 12}}>
              {I18n.t('dashboard.charts.detections.register_first_app')}
            </span>
            <Button
              className="register-app-button"
              onClick={onRegisterAppModalClick}
            >
              {I18n.t('apps_page.register_button')}
            </Button>
          </Layout>
        </Layout>
      )}
    </figure>
  )
}

export default DetectionsChart
