import _ from 'lodash'
import {AtlasCube, MiscCog, LmsWifi} from '@lookout/dingbats'

const specialCase = sourceWord => {
  if (!sourceWord) return ''

  const specialWords = [
    ['ios', 'iOS'],
    ['os', 'OS'],
    ['mitm', 'MITM'],
    ['vpn', 'VPN'],
    // to be continued
  ]
  const lcWord = _.lowerCase(_.trim(sourceWord))
  let returnWord = '' // "no match found"

  _.forEach(specialWords, ([targetWord, correctWord]) => {
    if (lcWord === targetWord) {
      returnWord = correctWord
      return false // exit forEach
    }
  })

  return returnWord
}

// transforms "STRING_THING" into "String thing". we get macro-case strings from the event feed.
// except some words should be capitalized specially (e.g., "iOS")
//
const macroCaseToTitleCase = s => {
  if (!s) return ''

  return s
    .trim()
    .toLowerCase()
    .split('_')
    .map((word, index) => {
      const specialCaseWord = specialCase(word)
      if (index === 0) return specialCaseWord || _.startCase(word)
      return specialCaseWord || word
    })
    .join(' ')
}

// The assessment names come from this list:
// https://source.corp.lookout.com/lookout/message_schemas/blob/master/protobuf/schemas/mtp/threat.proto#L24

// The corresponding names and descriptions are cribbed from MES:
// https://source.corp.lookout.com/frontend/mtp-console/blob/master/config/locales/console/en.yml#L316

export const getAssessmentName = name => {
  const nameKey = `assessment.name.${name.toLowerCase()}`
  return I18n.lookup(nameKey) ? I18n.t(nameKey) : macroCaseToTitleCase(name)
}

export const getAssessmentDesc = name => {
  const descKey = `assessment.desc.${name.toLowerCase()}`
  return I18n.lookup(descKey) ? I18n.t(descKey) : null
}

export const getRawAssessmentCategory = eventType =>
  ({
    APP_THREAT_DETECTED: 'application',
    APP_THREAT_RESOLVED: 'application',
    OS_THREAT_DETECTED: 'os',
    OS_THREAT_RESOLVED: 'os',
    NETWORK_THREAT_DETECTED: 'network',
    NETWORK_THREAT_RESOLVED: 'network',
  }[eventType])

export const getAssessmentCategory = category => {
  const categoryKey = `assessment.category.${category}`
  return I18n.lookup(categoryKey) ? I18n.t(categoryKey) : null
}

export const getIconFromCategory = category =>
  ({
    application: AtlasCube,
    os: MiscCog,
    network: LmsWifi,
  }[category])
