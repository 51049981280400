import _ from 'lodash'
import React from 'react'
import {Layout, SmallOutlineButton, typography} from '@lookout/ui'
import {useNavigate} from 'react-router-dom'
import logoHorizontalCentered from '../../assets/images/logo-horizontal-centered.svg'

const styles = {
  overlay: {
    backgroundColor: 'white',
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1e4,
  },

  container: {
    margin: '60px auto',
    padding: '40px',

    a: {
      whiteSpace: 'nowrap',
    },
  },

  logoMark: {
    alignSelf: 'center',
    width: 210,
    marginBottom: 50,
  },

  title: {
    fontSize: typography.toRem(30),
  },
}

const mapMessages = (messages, predicate) =>
  _.map(_.uniq(_.compact(_.castArray(messages))), predicate)

const FullScreenErrorMessage = ({
  className,
  title,
  description,
  resetErrorBoundary,
  showLogout = true,
}) => {
  const navigate = useNavigate()
  return (
    <Layout
      className={className}
      css={[{justifyContent: 'center'}, styles.overlay]}
      horizontal
    >
      <Layout vertical css={styles.container}>
        <img
          css={styles.logoMark}
          src={logoHorizontalCentered}
          alt={I18n.t('brand.name')}
        />
        <Layout vertical>
          <h1 className="error-title" css={styles.title}>
            {title}
          </h1>
          <div className="error-description" css={{a: {whiteSpace: 'nowrap'}}}>
            {_.isArray(description)
              ? mapMessages(description, message => (
                  <p key={message}>{message}</p>
                ))
              : description}
          </div>
          <Layout horizontal>
            <p>
              {resetErrorBoundary && (
                <SmallOutlineButton
                  className="try-again-button"
                  css={{marginRight: 20}}
                  onClick={() => resetErrorBoundary()}
                >
                  {I18n.t('error.generic.try_again')}
                </SmallOutlineButton>
              )}
              {showLogout && (
                <SmallOutlineButton
                  className="logout-button"
                  onClick={() => navigate('/logout')}
                >
                  {I18n.t('unauth.logout')}
                </SmallOutlineButton>
              )}
            </p>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default FullScreenErrorMessage
