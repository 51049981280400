import {colors, Tabs, Tab, useLoading, BarStackHorizontal} from '@lookout/ui'
import React, {useState, useMemo} from 'react'
import {format} from 'd3-format'
import {ThemeProvider} from '@emotion/react'
import _ from 'lodash'
import {deviceRegistrationsSampleData} from '../charts-test-data'

const androidColor = colors.teal150
const iosColor = colors.blue100
const transparentColor = colors.transparent
const registrationsChartMargins = {top: 10, left: 65, right: 40, bottom: 65}

const RegistrationsChartTooltip = ({data: {osVersion, count}}) => (
  <>
    <div className="registrations-os-ver">
      {I18n.t('dashboard.charts.registrations.os_version', {
        version: osVersion,
      })}
    </div>
    <div className="registrations-count">
      {I18n.t('dashboard.charts.detections.num_of_devices_with_count', {
        count: I18n.toNumber(count, {precision: 0}),
      })}
    </div>
  </>
)

const NoDataTooltip = () => (
  <div className="no-chart-data-tooltip">
    {I18n.t('dashboard.charts.registrations.no_data')}
  </div>
)

const transformDeviceRegistrationsTotals = deviceRegistrations => [
  {
    ios: Math.round(
      (deviceRegistrations.ios_total /
        deviceRegistrations.total_registrations) *
        100
    ),
    android: Math.round(
      (deviceRegistrations.android_total /
        deviceRegistrations.total_registrations) *
        100
    ),
  },
]

// TODO: to use semver OS version sort https://jira.corp.lookout.com/browse/JS-3231
const osVersionComparator = ({count: a}, {count: b}) => a - b

const RegistrationsChart = ({
  deviceRegistrations,
  isPending,
  dateRange,
  titleStyle,
  height,
  tabsTheme = {},
}) => {
  const [registrationsCategory, setRegistrationsCategory] = useState(
    'total_registrations'
  )
  const {elementRef, loadingVisible} = useLoading(isPending)

  const groupedDeviceRegistrations = useMemo(
    () => ({
      ...deviceRegistrations.value(),
      ios_versions: deviceRegistrations
        .value()
        .ios_versions?.sort(osVersionComparator),
      android_versions: deviceRegistrations
        .value()
        .android_versions?.sort(osVersionComparator),
    }),
    [deviceRegistrations]
  )

  const totalRegistrationsChartData = useMemo(
    () => transformDeviceRegistrationsTotals(groupedDeviceRegistrations),
    [groupedDeviceRegistrations]
  )

  const isRegistrationsChartDataEmpty = useMemo(
    () =>
      !(
        _.head(totalRegistrationsChartData).ios ||
        _.head(totalRegistrationsChartData).android
      ),
    [totalRegistrationsChartData]
  )

  return (
    <figure
      ref={elementRef}
      css={{
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        '&.loading.in > :not(figcaption)': {opacity: 0.5},
      }}
    >
      <figcaption>
        <h3 css={titleStyle} className="registrations-chart-title">
          {loadingVisible
            ? I18n.t('doc_title.loading')
            : I18n.t('dashboard.charts.registrations.title', {
                dateRange: I18n.t(`dashboard.filters.${dateRange}`),
              })}
        </h3>
      </figcaption>
      <ThemeProvider theme={tabsTheme}>
        <Tabs>
          <Tab
            className="total-registrations-tab"
            selected={registrationsCategory === 'total_registrations'}
            onClick={() => setRegistrationsCategory('total_registrations')}
          >
            <div css={{color: colors.black300}}>
              {groupedDeviceRegistrations.total_registrations?.toLocaleString()}
            </div>
            <span>{I18n.t('os.all')}</span>
          </Tab>
          <Tab
            className="ios-total-tab"
            selected={registrationsCategory === 'ios_total'}
            onClick={() => setRegistrationsCategory('ios_total')}
          >
            <div css={{color: iosColor}}>
              {groupedDeviceRegistrations.ios_total?.toLocaleString()}
            </div>
            <span>{I18n.t('os.ios')}</span>
          </Tab>
          <Tab
            className="android-total-tab"
            selected={registrationsCategory === 'android_total'}
            onClick={() => setRegistrationsCategory('android_total')}
          >
            <div css={{color: androidColor}}>
              {groupedDeviceRegistrations.android_total?.toLocaleString()}
            </div>
            <span>{I18n.t('os.android')}</span>
          </Tab>
        </Tabs>
      </ThemeProvider>

      {registrationsCategory === 'total_registrations' ? (
        <BarStackHorizontal
          {...(isRegistrationsChartDataEmpty
            ? {
                data: transformDeviceRegistrationsTotals(
                  deviceRegistrationsSampleData
                ),
                colorsRange: [transparentColor],
                Tooltip: NoDataTooltip,
              }
            : {
                data: totalRegistrationsChartData,
                colorsRange: [iosColor, androidColor],
              })}
          className="all-os-registrations-chart"
          keys={_.keys(_.head(totalRegistrationsChartData))}
          height={height}
          barStackText={{label: val => (val === 0 ? 0 : `${val}%`)}}
          axisBottom={{tickFormat: val => (val === 0 ? 0 : `${val}%`)}}
          showAxisLeft={false}
          yScaleConfig={{padding: 0.7}}
          legend={{labelFormat: val => I18n.t(`os.${val}`)}}
        />
      ) : (
        <BarStackHorizontal
          {...(registrationsCategory === 'ios_total' && {
            className: 'ios-registrations-chart',
            ...(groupedDeviceRegistrations.ios_total
              ? {
                  data: groupedDeviceRegistrations.ios_versions,
                  colorsRange: [iosColor],
                  Tooltip: RegistrationsChartTooltip,
                }
              : {
                  data: deviceRegistrationsSampleData.ios_versions,
                  colorsRange: [transparentColor],
                  Tooltip: NoDataTooltip,
                }),
          })}
          {...(registrationsCategory === 'android_total' && {
            className: 'android-registrations-chart',
            ...(groupedDeviceRegistrations.android_total
              ? {
                  data: groupedDeviceRegistrations.android_versions,
                  colorsRange: [androidColor],
                  Tooltip: RegistrationsChartTooltip,
                }
              : {
                  data: deviceRegistrationsSampleData.android_versions,
                  colorsRange: [transparentColor],
                  Tooltip: NoDataTooltip,
                }),
          })}
          keys={['count']}
          y={({osVersion}) => osVersion}
          axisLeft={{
            label: I18n.t('dashboard.charts.registrations.os_versions'),
          }}
          axisBottom={{
            label: I18n.t('dashboard.charts.registrations.registrations'),
            tickFormat: format('.2~s'),
          }}
          height={height}
          margins={registrationsChartMargins}
          barStackText={{css: {display: 'none'}}}
          css={{'.visx-legend': {visibility: 'hidden'}}} // making legend hidden to align with the height of total_registrations charts in the same layout
        />
      )}
    </figure>
  )
}

export default RegistrationsChart
