import {colors, Layout} from '@lookout/ui'
import React, {useState} from 'react'
import {AtlasArrowThinDown, MiscPlay} from '@lookout/dingbats'
import OffsiteLink from '../micro/offsite-link'
import protectedDeviceIcon from '../../assets/images/protected-device.svg'

const docItemStyle = {
  display: 'inline-block',
  borderLeft: `1px solid ${colors.lightBlueGray300}`,
  padding: '0 0 0 15px',
  '> p': {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 16,
  },
  '> ul': {
    padding: 0,
    listStyle: 'none',
    '> li': {
      marginBottom: 0,
    },
  },
}

const mainIconWidth = 35
const mainTitleLeftMargin = 15
const playIconCropFix = -11

export default function DocsCollapsible() {
  const [isOpen, setIsOpen] = useState(false)
  const [animate, setAnimate] = useState(false)

  const toggleCollapsible = value => {
    setAnimate(true)
    setIsOpen(value)
  }

  return (
    <>
      <Layout
        css={{alignItems: 'center', justifyContent: 'space-between'}}
        horizontal
      >
        <h3
          css={{
            display: 'flex',
            alignItems: 'center',
            letterSpacing: 0.4,
            color: colors.black300,
            whiteSpace: 'nowrap',
          }}
        >
          <img
            src={protectedDeviceIcon}
            css={{width: mainIconWidth}}
            alt={I18n.t('dashboard.docs.icon')}
          />
          <span
            css={{
              verticalAlign: 'middle',
              marginLeft: mainTitleLeftMargin,
              marginRight: 8,
            }}
          >
            {I18n.t('dashboard.docs.title')}
          </span>
          <AtlasArrowThinDown
            className="toggle-docs-collapsible"
            onClick={() => toggleCollapsible(!isOpen)}
            css={[
              {
                height: 22,
                width: 22,
                verticalAlign: 'middle',
                transition: animate ? 'transform .3s ease' : 'none',
                transformOrigin: 'center center',
                color: colors.black100,
                cursor: 'pointer',
              },
              isOpen && {transform: 'rotate(180deg)'},
            ]}
          />
        </h3>
      </Layout>
      <div
        css={{
          display: isOpen ? 'block' : 'none',
          marginLeft: mainIconWidth + mainTitleLeftMargin,
        }}
      >
        {/* NOTE we'll have multiple "steps" in future (see mockups) */}
        <div css={docItemStyle}>
          {/* NOTE h4 could be more semantic, but looks like gnosis global styles override what's in app.css */}
          <p>Download Lookout SDK</p>
          <ul>
            <li>
              <MiscPlay
                fill={colors.lookout}
                height={32}
                width={32}
                css={{
                  marginLeft: playIconCropFix,
                  verticalAlign: 'middle',
                }}
              />
              <OffsiteLink
                href={global.config.ios_guide_url}
                css={{fontWeight: 500}}
                target="_blank"
                className="download-ios-guide-link"
              >
                {I18n.t('home.bottom_1.link_ios')}
              </OffsiteLink>
            </li>
            <li>
              <MiscPlay
                fill={colors.lookout}
                height={32}
                width={32}
                css={{
                  marginLeft: playIconCropFix,
                  verticalAlign: 'middle',
                }}
              />
              <OffsiteLink
                href={global.config.android_guide_url}
                target="_blank"
                css={{fontWeight: 500}}
                className="download-android-guide-link"
              >
                {I18n.t('home.bottom_1.link_android')}
              </OffsiteLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
