import _ from 'lodash'
import * as lookoutRequest from '@lookout/request'
import {getToken} from './token-cache'

// The use of _.get(lookoutRequest, 'request') is to cheat on Webpack module
// resolution optimizer. Since we stub ES module object, we want to make sure
// we access module exports of that object and not from the standalone module file

const requestWithToken = (url, options) => {
  const headers = {Authorization: `Bearer ${getToken()}`}
  return _.get(lookoutRequest, 'request')(url, _.merge({headers}, options))
}

export default requestWithToken
