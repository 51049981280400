import {TokenChecker} from '@gnosis/oauth'
import {
  FullScreenSpinner,
  Layout,
  Notifications,
  useNotificationsState,
  NotificationsContext,
} from '@lookout/ui'
import React, {useEffect, Suspense} from 'react'
import {useResourceOnce} from '@lookout/suspense'
import {Outlet, useLocation, Navigate} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {readCache, readEnt} from './app-init-service'
// eslint-disable-next-line import/no-cycle
import FullScreenErrorMessage from './components/errors/fullscreen-error-message'
import SideNav from './components/nav/side-nav'
import {initMixpanel} from './lib/analytics/mixpanel-helper'
import {setToken} from './lib/api/token-cache'
import initSentry from './sentry-helper'
import NetworkErrorBoundary from './lib/network-error-boundary'

const GlobalCache = ({children, cache, ent}) => {
  if (!global.cache) {
    global.cache = {...cache.value(), ent: ent.value(), date: Date.now()}
    initMixpanel()
  }
  return children
}

const AppInit = () => {
  // react-router-dom hook that returns the next location we're about to
  // render. In child contexts, like within a page component, this hook may
  // return the current route.
  const {notifications, context: notificationsContext} = useNotificationsState()
  const cache = useResourceOnce(readCache)
  const ent = useResourceOnce(readEnt)

  useEffect(() => initSentry(), [])

  return (
    <NetworkErrorBoundary
      fallbackRender={({resetErrorBoundary}) => (
        <FullScreenErrorMessage
          className="app-init-error"
          title={I18n.t('error.generic.title')}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
    >
      <Suspense
        fallback={
          <div className="app-init-pending">
            <FullScreenSpinner />
          </div>
        }
      >
        <GlobalCache cache={cache} ent={ent}>
          <Layout horizontal css={{height: '100vh'}}>
            <SideNav />
            <Layout
              css={{
                width: 'calc(100% - 200px)',
                overflow: 'auto',
              }}
            >
              <Notifications
                className="app-notifications"
                notifications={notifications}
                css={{position: 'sticky', top: 0, zIndex: 2}}
              />
              <NotificationsContext.Provider value={notificationsContext}>
                <Outlet />
              </NotificationsContext.Provider>
            </Layout>
          </Layout>
        </GlobalCache>
      </Suspense>
    </NetworkErrorBoundary>
  )
}

const AppAuth = () => {
  const location = useLocation()
  return global.Cypress ? (
    <AppInit />
  ) : (
    <TokenChecker
      currentRoute={location.pathname}
      tokenVendorUrl={global.config.token_vendor_url}
      tokenRequest={{
        callbackUrl: global.config.token_redirect_url,
        clientId: global.config.token_vendor_client_id,
      }}
      requiredAuthorizations={['sdk']}
      onValid={setToken}
      render={authorized =>
        authorized ? <AppInit /> : <Navigate to="/unauthorized" />
      }
    />
  )
}

const App = () => (
  <ErrorBoundary
    fallbackRender={({resetErrorBoundary}) => (
      <FullScreenErrorMessage
        className="app-init-error"
        title={I18n.t('error.generic.title')}
        resetErrorBoundary={resetErrorBoundary}
      />
    )}
  >
    <AppAuth />
  </ErrorBoundary>
)
export default App
