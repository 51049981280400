import React from 'react'
import {Layout, Spinner} from '@lookout/ui'

const Fallback = ({className, message, spinner}) => (
  <Layout
    className={className}
    css={{minHeight: '33vh', alignItems: 'center', justifyContent: 'center'}}
    horizontal
  >
    {spinner ? <Spinner size={10} /> : <p>{message}</p>}
  </Layout>
)

export default Fallback
