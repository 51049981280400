import {useEffect} from 'react'
import {trackView} from './mixpanel-helper'

const useTrackView = page => {
  useEffect(() => {
    trackView(page)
  }, [page])
}

export default useTrackView
