import React from 'react'
import {Container} from '../micro/container'
import Markdownify from '../micro/markdownify'
import IosMarkdown from './ios.md'

const IosDocs = () => (
  <Container className="ios-docs-page">
    <Markdownify md={IosMarkdown} />
  </Container>
)

export default IosDocs
