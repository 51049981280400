import React from 'react'
import {Layout, SelectCopy, colors} from '@lookout/ui'
import {MiscSignCheck} from '@lookout/dingbats'

const SelectToCopy = ({textElement}) => (
  <SelectCopy textElement={textElement}>
    {(copyHandler, selectSuccess) => {
      const textStyle = {
        color: selectSuccess ? colors.primary : colors.darkGray100,
        cursor: 'pointer',
      }

      return (
        // TODO: fix this eslint warning by satisfying it
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <h3 css={{display: 'inline-flex'}} onClick={copyHandler}>
          <Layout
            css={{
              // Height value for layout prevents text shift when icon
              // appears
              height: '32px',
              display: 'inline-flex',
              alignItems: 'center',
            }}
            horizontal
          >
            {selectSuccess && (
              <MiscSignCheck
                css={{
                  marginLeft: '-6px',
                  fill: colors.primary,
                  width: '32px',
                  height: '32px',
                }}
              />
            )}
            <span css={textStyle}>
              {selectSuccess
                ? I18n.t('select_copy.copied')
                : I18n.t('select_copy.action_text')}
            </span>
          </Layout>
        </h3>
      )
    }}
  </SelectCopy>
)

export default SelectToCopy
