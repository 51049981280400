const getPageTitle = titleKey => {
  const baseTitle = I18n.t('doc_title.base')
  const messageObject =
    I18n.lookup(`doc_title.${titleKey}`) && I18n.t(`doc_title.${titleKey}`)
  return messageObject
    ? I18n.t('doc_title.combined', {
        base: baseTitle,
        subtitle: messageObject,
      })
    : baseTitle
}

export default getPageTitle
