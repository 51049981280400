import {OSIcon, Table, typography} from '@lookout/ui'
import React from 'react'
import classes from '../../lib/utils/classes'
import {belowQuery, screenXsMax} from '../../lib/styles/breakpoints'
import {DoubleHeader} from '../micro/double-header'
import {mobileResponsiveMargin} from '../../lib/styles/layout-styles'

const AppSummary = ({app}) => {
  const {userName, userEmail, packageName, os, comment, appName} = app.value()
  return (
    <>
      <div
        css={{
          margin: 40,
          marginLeft: 0,
          paddingLeft: 10,
          ...mobileResponsiveMargin,
        }}
      >
        <h1 className="blush app-name">{appName}</h1>
      </div>
      <Table
        className="app-info-table"
        css={[
          {
            minWidth: 600,
            'thead th': {
              borderBottom: 'none',
            },
            [belowQuery({max: screenXsMax})]: {
              minWidth: 400,
              thead: {
                tr: {
                  th: {
                    paddingLeft: 0,
                  },
                },
              },
              tbody: {
                tr: {
                  td: {
                    paddingLeft: 0,
                  },
                },
              },
            },
          },
        ]}
      >
        <thead>
          <tr>
            <th>{I18n.t('app_details.package_name')}</th>
            <th>{I18n.t('app_details.developer')}</th>
            <th>{I18n.t('app_details.os')}</th>
            <th>{I18n.t('app_details.comments')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="package-name">{packageName}</td>
            <td>
              <DoubleHeader
                className="developer"
                top={userName}
                bottom={userEmail}
              />
            </td>
            <td>
              <OSIcon className={classes('os', os)} os={os} />
            </td>
            <td className="comment" css={typography.breakText}>
              {comment}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default AppSummary
