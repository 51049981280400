import React from 'react'
import PropTypes from 'prop-types'
import {Layout, colors} from '@lookout/ui'

const circleContainerStyle = {
  width: '2.5em',
  height: '2.5em',
  fontSize: '14px',
  color: colors.gray100,
  backgroundColor: colors.blueGray50,
  borderRadius: '50%',
}

export default function CircleText(props) {
  return (
    <Layout
      horizontal
      css={[
        {alignItems: 'center', justifyContent: 'center'},
        circleContainerStyle,
      ]}
      {...props}
    />
  )
}

CircleText.propTypes = {
  className: PropTypes.string,
}
