import React from 'react'
import PropTypes from 'prop-types'
import {Layout, colors} from '@lookout/ui'
import CircleText from '../micro/circle-text'

function parseUserName(userName) {
  const parsed = {
    initials: null,
    firstName: null,
  }

  if (userName) {
    const words = userName.split(' ')
    if (words.length > 0) parsed.firstName = words[0].trim().slice(0, 10)
    if (words.length <= 2)
      parsed.initials = words.reduce(
        (initials, word) => initials + word.charAt(0),
        ''
      )
  }
  return parsed
}

export default function FooterTrigger({userName}) {
  const {initials, firstName} = parseUserName(userName)

  return (
    <Layout css={{color: colors.lightGray100, alignItems: 'center'}} horizontal>
      {initials && (
        <CircleText className="admin-initials" css={{marginRight: '0.5em'}}>
          {initials}
        </CircleText>
      )}{' '}
      <span className="admin-name">
        {firstName || I18n.t('user_menu.title')}
      </span>
    </Layout>
  )
}

FooterTrigger.propTypes = {
  userName: PropTypes.string,
}
