import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'url-polyfill'

import React from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import Style from './style'
import AppRoutes from './app-routes'
import './favicons'
import '../config/locales/console/en'
/* eslint-disable import/order, spaced-comment */
///////////////
/////////////////////////////////////////
//////////
/* eslint-enable spaced-comment */

/* eslint-disable spaced-comment */
///////////////
/////////////////////
///////////////////////////////////
////////////////////////////////////
//////////////
///////////////////
////////////////////////
//////////////
//////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
/////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
///////////////////
///////////
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
//////////
////////
//////
////
 
//////////
/* eslint-enable spaced-comment */

createRoot(global.document.getElementById('content')).render(
  <>
    <Style />
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </>
)
